<template>
  <div class="my-4">
    <h2>
      Datos Fiscales
      <v-btn
        class="mx-4 py-4"
        fab
        small
        color="primary"
        @click="showForm = !showForm"
      >
        <v-icon dark>
          {{ this.showForm ? 'mdi-minus' : 'mdi-plus' }}
        </v-icon>
      </v-btn>
    </h2>
    <v-row
      justify="center"
      class="px-2 mx-4 my-6"
    >
      <v-card
        v-if="showForm"
        class="px-4 py-4"
        max-width="300"
      >
        <p class="text-primary">
        {{ this.edit ? 'Modificar' : 'Nuevos' }} Datos de Facturación:
        </p>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newFiscal.tax_id"
                label="RFC o taxID"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="newFiscal.nombre_o_razon_social"
                label="Nombre o Razón Social"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="userType === 'Cliente'"
              cols="12"
            >
              <v-checkbox
                class="my-0 py-0"
                v-model="newFiscal.predeterminado"
                label="Predeterminado"
              ></v-checkbox>
            </v-col>
            <v-col
                cols="12"
            >
              <v-select
                v-model="newFiscal.uso_factura"
                label="Uso Factura"
                :items="usosFactura"
                :rules="[rules.required]"
                :item-text="item => `${item.codigo} - ${item.dato}`"
                item-value="id"
                dense
                solo
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            :disabled="!valid"
            color="primary"
            @click="sendFiscal"
          >
            {{ buttonAction }}
          </v-btn>
          <v-btn
            color="primary"
            @click="cleanForm"
          >
            limpiar campos 
          </v-btn>
        </v-card-actions>
      </v-card>
      
    </v-row>
    <v-row
      class="my-4"
      justify="center"
    >
      <v-card
        v-for="fiscal in userFiscales"
        :key="fiscal.id"
        max-width="300"
        class="mx-4 my-4"
        :style="selectedUserFiscal === fiscal.id ?
                'border: 2px solid #FF4C00;' :
                ''"
      >
        <v-card-text>
          <div v-show="fiscal.predeterminado">Predeterminado</div>
          <p class="display-1 text--primary">
            {{ fiscal.tax_id}}
          </p>
          <div>
            Razón social: {{ fiscal.nombre_o_razon_social }}<br>
            Uso Factura: {{ getCodigoUsoFactura(fiscal.uso_factura) }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="!selectedFiscal"
            text
            color="primary"
            @click="addEdit(fiscal)"
          >
            Editar
          </v-btn>
          <v-btn
            v-if="!selectedFiscal"
            text
            color="primary"
            @click="deleteFiscal(fiscal.id)"
          >
            Eliminar 
          </v-btn>
          <v-btn
            v-if="selectedFiscal"
            text
            color="primary"
            @click="emitSelectedFiscal(fiscal)"
          >
            Seleccionar 
          </v-btn> 
        </v-card-actions>
      </v-card>
    </v-row>
  </div>
</template>

<script>
import { ApiAuth, axiosBase } from '@/api_utils/axios-base'
import { mapState } from 'vuex'
import { validationMixin } from '@/mixins/formsValidations.js'

export default {
  mixins: [validationMixin],
  props: {
    selectedFiscal: Boolean
  },
  data() {
    return {
      fiscales: null,
      usosFactura: [],
      fakeDataSelect: [
        {
          text: 'Gastos en general',
          id: 1
        },
        {
          text: 'Gastos en específico',
          id: 2
        }
      ],
      newFiscal: {
        tax_id: null,
        nombre_o_razon_social: null,
        autor: null,
        direccion_fiscal: 1, // este dato está de más
        uso_factura: null, // se baja la info del sat 
        predeterminado: false,
      },
      fiscalModify: null,
      edit: false,
      selectedUserFiscal: null,
      showForm: false,
      valid: true,
      userType: "",
    }
  },
  created() {
    this.getUsosFactura()
    this.getUserExtraData()
  },
  computed: {
    ...mapState('usuario', ['userData']),
    buttonAction() {
      return this.edit ? "Modificar" : "Agregar"
    },
    userFiscales() {
      return this.fiscales
    }
  },
  methods: {
    async getUsosFactura() {
      const response = await ApiAuth.get("/personas/api/uso-factura/")
      const responseData = await response.data
      this.usosFactura = responseData
    },
    async getUserExtraData() {
      this.userType = this.userData.tipo_usuario
      if(this.userType === "Cliente") {
        try {
          let response = await ApiAuth.get(`/personas/api/cliente/?usuario_id=${this.userData.id}`)
          let responseData = await response.data
          if (responseData.length) {
            const data = responseData[0]
            this.fiscales = data.datos_fiscales
            this.clienteId = data.id
            if(this.fiscales.length > 0) {
              let dFiscales = this.fiscales.find(dato => dato.predeterminado === true)
              this.emitPredeterminadaFiscal(dFiscales)
            }
          }
          
        } catch(error) {
          console.error("error getting datos fiscales", error)
        }
      } else if(this.userType === "Distribuidor") {
        try {
          let response = await ApiAuth.get(`/personas/api/distribuidor/?usuario_id=${this.userData.id}`)
          let data = await response.data[0]
          
          this.fiscales = data.datos_fiscales
          this.distribuidorId = data.id
        } catch(error) {

        }
      }
    },
    addEdit(fiscal) {
      let {id, fecha_elaboracion, estatus_sistema, ...currentFiscal} = fiscal
      this.fiscalModify = fiscal
      this.newFiscal = currentFiscal
      this.edit = true
      this.showForm = true
    },
    async deleteFiscal(fiscalId) {
      try {
        let deleteResp = await ApiAuth.delete(`/personas/api/datos-fiscales/${fiscalId}/`)
        this.getUserExtraData()
      } catch(error) {
        console.error("error deleting fiscal")
      }
    },
    cleanForm() {
      Object.keys(this.newFiscal).forEach(k => this.newFiscal[k]= "")
      this.edit = false
    },
    emitSelectedFiscal(fiscal) {
      this.$emit('fiscalSeleccionado', fiscal)
      this.selectedUserFiscal = fiscal.id
    },
    emitPredeterminadaFiscal(fiscal) {
      this.$emit('fiscalPredeterminada', fiscal)
      this.selectedUserFiscal = fiscal.id
    },
    async sendFiscal() {
      if(!this.$refs.form.validate()) {
        return 

      } else if(this.edit) {
        try {
          this.$store.commit('setLoading', true)
          if(this.newFiscal.predeterminado) {
            this.setPredeterminadoFalse()
          }
          let response = await ApiAuth.put(`/personas/api/datos-fiscales/${this.fiscalModify.id}/`,
            this.newFiscal
          )
          this.getUserExtraData()
          this.showForm = false
          setTimeout(() => { this.$store.commit('setLoading', false)}, 500)

          this.cleanForm()

        } catch(error) {
          console.error("error modifying fiscal", error)
        }
        
      } else {
        try {
          this.$store.commit('setLoading', true)
          if(this.newFiscal.predeterminado) {
            this.setPredeterminadoFalse()
          }
          this.newFiscal.autor = this.userData.id

          let requestUrl = ""
          let requestBody = {}
          let response = await ApiAuth.post('/personas/api/datos-fiscales/', this.newFiscal)
          if(this.userType === "Cliente") {
            requestUrl = '/personas/agregar-datosfiscales-cliente'
            requestBody = { id_datos_fiscales: response.data.id, id_cliente: this.clienteId }
            
          } else if(this.userType === "Distribuidor") {
            requestUrl = '/personas/agregar-datosfiscales-distribuidor'
            requestBody = { id_datos_fiscales: response.data.id, id_distribuidor: this.distribuidorId}
          }

          let responseDistribuidor = await ApiAuth.post(requestUrl, requestBody)
          this.getUserExtraData()
          this.showForm = false
          setTimeout(() => { this.$store.commit('setLoading', false)}, 500)
          
          this.cleanForm()

        } catch(error) {
          console.error("error creating new fiscal", error)
        }
      }
    },
    setPredeterminadoFalse() {
      let dFiscales = this.fiscales.find(dato => dato.predeterminado === true)
      if(dFiscales) {
        dFiscales.predeterminado = false
        console.log("encontro uno: ", dFiscales)
        ApiAuth.put(`/personas/api/datos-fiscales/${dFiscales.id}/`, dFiscales)
          .then(resp => {
            console.log("modificado!", resp)
          })
      } else {
        console.log("no encontre nada")
        return
      }
    },
    getCodigoUsoFactura(idUso) { 
      const uso = this.usosFactura.find(u => u.id === idUso)
      return uso ? uso.codigo : idUso
    },
  }
}
</script>
